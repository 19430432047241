<template>
  <capillaries-template
    :gender="gender"
    :prominent-blood-vessels="prominentBloodVessels"
    :prominent-blood-vessels-ai-selected="prominentBloodVesselsAiSelected"
    :first-blood-vessel-occurrance="firstBloodVesselOccurrance"
    :blood-vessel-locations="bloodVesselLocations"
    :redness-detail-questions-visible="isRednessDetailQuestionsVisible"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import CapillariesTemplate from '@/modules/questionnaire/components/steps/photo-analysis/skin-redness/capillaries/CapillariesTemplate';

import { makeStep, mapAiSelectedFields, resetStoreValues } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';
import { no, yes } from '@/modules/questionnaire/api/helpers';

const { field, requiredField, requiredArrayField } = fieldBuilder;

const AI_SELECTED_FIELDS = ['prominentBloodVessels'];

const FIELDS_TO_RESET = ['bloodVesselLocations', 'firstBloodVesselOccurrance'];

const REQUIRED_FIELDS = ['firstBloodVesselOccurrance'];

const FIELDS = [
  field('gender'),
  requiredArrayField('bloodVesselLocations'),
  ...AI_SELECTED_FIELDS.map(requiredField),
  ...REQUIRED_FIELDS.map(requiredField)
];

export default {
  name: 'Capillaries',
  components: {
    CapillariesTemplate
  },
  mixins: [makeStep(FIELDS), mapAiSelectedFields(AI_SELECTED_FIELDS), resetStoreValues],
  computed: {
    isRednessDetailQuestionsVisible() {
      return yes(this.prominentBloodVessels);
    }
  },
  watch: {
    prominentBloodVessels(newValue) {
      if (no(newValue)) {
        this.resetStoreFieldsByName(FIELDS_TO_RESET);

        this.showNextStep();

        return;
      }

      this.scrollTo('#facial-capillary-occurrence');
    },
    firstBloodVesselOccurrance() {
      this.scrollTo('#facial-capillary-locations');
    }
  },
  methods: {
    isFieldVisible(fieldName) {
      if (FIELDS_TO_RESET.includes(fieldName)) {
        return this.isRednessDetailQuestionsVisible;
      }

      return true;
    }
  }
};
</script>
